import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { ArrowDown } from 'src/assets/icons/customIcons/ArrowDown'
import { ArrowUp } from 'src/assets/icons/customIcons/ArrowUp'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Card } from 'src/tailwind/components/Card'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import PopoverComponent from 'src/views/components/PopoverComponent'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

interface FormikValues {
  comment: string
  scores: {
    [competencyId: string]: {
      criterias: {
        [criteriaId: string]: {
          score: string
          feedback: string
        }
      }
    }
  }
}

export default function LecturerPortfolioEvaluationPage(): JSX.Element | null {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('portfolio:evaluation')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const params = useParams() as {
    readonly sectionId: string
    readonly workId: string
  }
  const { pathname } = useLocation()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const navigate = useNavigate()

  const section = useApi({
    endpoint: Api.getLecturerPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const work = useApi({
    endpoint: Api.getLecturerPortfolioSectionWork,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          SectionId: params.sectionId!,
          id: params.workId!,
        },
      }),
      [headers, params.sectionId, params.workId]
    ),
    suspense: false,
  })

  const sectionCompetencies = useApi({
    endpoint: Api.getLecturerPortfolioCompetencies,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${section.data.name}`, path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works` },
    {
      page: `${t('portfolio:assignment')}`,
      path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`,
    },

    { page: `${t('portfolio:evaluation')}`, path: pathname },
  ]

  const initialValues = React.useMemo(() => {
    const initialValues: FormikValues = {
      comment: '',
      scores: {},
    }

    if (sectionCompetencies.data?.data) {
      sectionCompetencies.data.data.forEach((competency: Api.PortfolioCompetency) => {
        const competencyId: string = competency.id
        initialValues.scores[competencyId] = { criterias: {} }

        competency.criterias?.forEach((criteria: Api.PortfolioCompetenciesCriteria) => {
          const criteriaId: string = criteria.id
          initialValues.scores[competencyId]!.criterias[criteriaId] = {
            score: '',
            feedback: '',
          }
        })
      })
    }

    return initialValues
  }, [sectionCompetencies.data.data])

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    function filterScores(initialScores: FormikValues): Api.ScoreEvaluation {
      const filteredScores: any = {}
      for (const scoreKey in initialScores.scores) {
        const score = initialScores.scores[scoreKey]
        if (score?.criterias[1]?.score !== '' && score?.criterias[1]?.feedback !== '') {
          filteredScores[scoreKey] = score
        }
      }
      return filteredScores
    }

    const singleScore = filterScores(values)

    const filteredScores = {
      ...values,
      scores: singleScore,
    }

    await Api.patchLecturerPortfolioSectionsWorks({
      body: filteredScores,
      args: {
        sectionId: params.sectionId,
        workId: params.workId,
      },
      headers,
    })
    toast.success(t('common:updated_successfully'))
    setSuccessfullySubmited(true)
  }

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`)
    }
  }, [successfullySubmited, params.workId, navigate, locale, params.sectionId])

  if (work.data != null) {
    return (
      <Form initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        <FormError />
        <Section
          title={t('portfolio:portfolio_evaluation')}
          icon={<Portfolio />}
          breadcrubms={breadcrumbsItems}
          rightElement={
            <BackButton link={`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`} />
          }
        >
          <SuspenseWrapper>
            <FormContent work={work.data} competencies={[...(sectionCompetencies?.data?.data || [])]} />
          </SuspenseWrapper>
        </Section>
      </Form>
    )
  } else return null
}

function FormContent({
  work,
  competencies,
}: {
  work: Api.LecturerPortfolioUserWork
  competencies: Api.PortfolioCompetency[]
}): React.ReactElement | null {
  const t = useTranslatable()

  const requiredCompetencies = new Set(
    work.competencies?.map((competencyItem: Api.PortfolioCompetency) => competencyItem.id)
  )

  const scoreOptions = (min: number, max: number) => {
    const options = []
    for (let i = min; i <= max; i++) {
      options.push({ value: i.toString(), label: i.toString() })
    }
    return options
  }

  const required = competencies.filter((competency: Api.PortfolioCompetency) => requiredCompetencies.has(competency.id))
  const nonRequired = competencies.filter(
    (competency: Api.PortfolioCompetency) => !requiredCompetencies.has(competency.id)
  )

  const [isCompetencyVisible, setCompetencyVisible] = useState(false)

  const handleCardClick = () => {
    setCompetencyVisible(!isCompetencyVisible)
  }

  return (
    <>
      <div className="mb-6">
        {required?.map((competency: Api.PortfolioCompetency) => (
          <div key={competency.id}>
            <Table.Table className="mb-6 mt-3 !overflow-x-visible" key={competency.id}>
              <Table.Thead>
                <Table.Tr className="!border-b !border-borderGrey">
                  <Table.Th className="w-1/3">
                    {competency.name}{' '}
                    {requiredCompetencies.has(competency.id) && <span className="pl-1 text-primaryRed">*</span>}
                  </Table.Th>
                  <Table.Th className="w-[160px]" />
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {competency.criterias?.map((criteria) => (
                  <Table.Tr key={criteria.name}>
                    <Table.Td className="!align-top">
                      {t('portfolio:criterion') + `: `} {criteria.name}
                      <div className="mb-3 mr-4 flex items-center">
                        <label className="text-primaryTextColor" htmlFor={criteria.id}>
                          <span>
                            {criteria.name}
                            <PopoverComponent>
                              <span
                                className="mb-3 flex py-2"
                                dangerouslySetInnerHTML={{ __html: criteria.description }}
                              />
                            </PopoverComponent>
                          </span>
                        </label>
                      </div>
                    </Table.Td>
                    <Table.Td className="!align-top">
                      <div className="flex !align-top">
                        <SelectInput
                          name={`scores.${competency.id}.criterias.${criteria.id}.score`}
                          options={scoreOptions(criteria.min, criteria.max)}
                          placeholder={t('common:score')}
                        />
                        <div>
                          <PopoverComponent>
                            <span className="flex flex-col">
                              {t('portfolio:portfolio_score_value')}: {criteria.min} - {criteria.max}{' '}
                            </span>
                          </PopoverComponent>
                        </div>
                      </div>
                    </Table.Td>
                    <Table.Td className="!pt-3 !align-top">
                      <TextareaInput
                        name={`scores.${competency.id}.criterias.${criteria.id}.feedback`}
                        className="className='!mt-0' h-[120px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
                        placeholder={t('portfolio:assignment_feedback')}
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </div>
        ))}
        {nonRequired.length > 0 && (
          <Card className="my-6 items-center justify-between rounded-md border !border-borderGrey bg-transparent px-3 py-6 pr-[20px] text-primaryTextColor">
            <div className="w-full items-center pl-3" onClick={handleCardClick}>
              <div className="flex w-full items-center justify-between">
                <span className="font-bold">{t('portfolio:add_competency')}</span>
                {isCompetencyVisible ? <ArrowUp /> : <ArrowDown />}
              </div>
            </div>
            {isCompetencyVisible && (
              <div className="mt-6">
                {nonRequired?.map((competency: Api.PortfolioCompetency) => (
                  <div key={competency.id}>
                    <Table.Table className="mb-6 mt-3 !overflow-x-visible" key={competency.id}>
                      <Table.Thead>
                        <Table.Tr className="!border-b !border-borderGrey">
                          <Table.Th className="w-1/3">
                            {competency.name}
                            {requiredCompetencies.has(competency.id) && <span className="pl-1 text-primaryRed">*</span>}
                          </Table.Th>
                          <Table.Th className="w-[160px]" />
                          <Table.Th />
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {competency.criterias?.map((criteria) => (
                          <Table.Tr key={criteria.name}>
                            <Table.Td className="!align-top">
                              {t('portfolio:criterion') + `: `} {criteria.name}
                              <div className="mb-3 mr-4 flex items-center">
                                <label className="text-primaryTextColor" htmlFor={criteria.id}>
                                  <span>
                                    {criteria.name}
                                    <PopoverComponent>
                                      <span
                                        className="mb-3 flex py-2"
                                        dangerouslySetInnerHTML={{ __html: criteria.description }}
                                      />
                                    </PopoverComponent>
                                  </span>
                                </label>
                              </div>
                            </Table.Td>
                            <Table.Td className="!align-top">
                              <div className="flex !align-top">
                                <SelectInput
                                  name={`scores.${competency.id}.criterias.${criteria.id}.score`}
                                  options={scoreOptions(criteria.min, criteria.max)}
                                  placeholder={t('common:score')}
                                />
                                <div>
                                  <PopoverComponent>
                                    <span className="flex flex-col">
                                      {t('portfolio:portfolio_score_value')}: {criteria.min} - {criteria.max}{' '}
                                    </span>
                                  </PopoverComponent>
                                </div>
                              </div>
                            </Table.Td>
                            <Table.Td className="!pt-3 !align-top">
                              <TextareaInput
                                name={`scores.${competency.id}.criterias.${criteria.id}.feedback`}
                                className="className='!mt-0' h-[120px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
                                placeholder={t('portfolio:assignment_feedback')}
                              />
                            </Table.Td>
                          </Table.Tr>
                        ))}
                      </Table.Tbody>
                    </Table.Table>
                  </div>
                ))}
              </div>
            )}
          </Card>
        )}

        <div className="my-3 w-full">
          <TextareaInput
            name="comment"
            label={t('portfolio:comment')}
            className="className='!mt-0' h-[160px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
          />
        </div>
      </div>
      <div className="my-3 flex w-full justify-end">
        <Button type="submit" variant="blue">
          {t('portfolio:evaluation')}
        </Button>
      </div>
    </>
  )
}
